exports.components = {
  "component---src-templates-business-jsx": () => import("./../../../src/templates/Business.jsx" /* webpackChunkName: "component---src-templates-business-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/Default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-dynamic-zip-packages-jsx": () => import("./../../../src/templates/DynamicZipPackages.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-jsx" */),
  "component---src-templates-dynamic-zip-packages-spanish-jsx": () => import("./../../../src/templates/DynamicZipPackagesSpanish.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-spanish-jsx" */),
  "component---src-templates-one-trust-privacy-policy-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-jsx" */),
  "component---src-templates-one-trust-privacy-policy-spanish-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicySpanish.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-spanish-jsx" */),
  "component---src-templates-spanish-jsx": () => import("./../../../src/templates/Spanish.jsx" /* webpackChunkName: "component---src-templates-spanish-jsx" */)
}

